import React from "react"
import classNames from "classnames"

import Layout from "~/components/layout"
import Container from "~/components/ui/container"
import { RichText } from "prismic-reactjs"

import * as styles from "./index.module.scss"

interface Props {
  pageContext: any
}

const InfoTemplate: React.FC<Props> = ({ pageContext: { data } }) => {
  const [isAnimated, setIsAnimated] = React.useState(false)
  React.useEffect(() => {
    setIsAnimated(true)
  }, [setIsAnimated])

  return (
    <Layout>
      <article className={styles.root}>
        <div
          className={classNames(styles.animation, {
            [styles.animation__animated]: isAnimated,
          })}
        ></div>

        <Container>
          <h1 className={classNames("headline--decorated", styles.headline)}>
            Urus <span>{data.title.text}</span>
          </h1>
          <div className={styles.content}>
            <RichText render={data.text.raw} />
          </div>
        </Container>
      </article>
    </Layout>
  )
}

export default InfoTemplate
